// export const SERVERURL = `https://thecryptohub.com/api/api`;

//mine
// export const SERVERURL = `https://crypto-back-new-production-9a84.up.railway.app/api`;
//anser
// export const SERVERURL = `https://crypto-back-new-production-c9c3.up.railway.app/api`;
// export const SERVERURL = `https://crypto-back-new-production.up.railway.app/api`;

//export const SERVERURL = `http://localhost:4000`;
export const SERVERURL = `https://ample-healing-production.up.railway.app`;
